<template>
  <a-row :gutter="24" type="flex">

    <!-- Projects Table Column -->
    <a-col :span="24" class="mb-24">

      <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
        <template #title>
          <a-row type="flex" align="middle">
            <a-col :span="24" :md="12" class="top-left-header">
              <h5 class="font-semibold m-0">
                Category List <a-spin v-if="confirmLoading"/>
              </h5>
            </a-col>
            <a-col :span="24" :md="12" class="top-right-header">
              <a-button @click="modalOpen = true">Add New Category</a-button>
            </a-col>
          </a-row>
        </template>
        <a-table :columns="columnData"
                 :data-source="categoryList"
                 :pagination="false">

          <template slot="name" slot-scope="text">
            <a>{{ text }}</a>
          </template>

          <template slot="updateBtn" slot-scope="row">
            <div>
              <a-button type="link" :data-id="row.key">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                     @click="showUpdateModal(row.details)">
                  <path class="fill-gray-7"
                        d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
                  <path class="fill-gray-7"
                        d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
                </svg>
              </a-button>
            </div>
          </template>
        </a-table>

        <div class="pagination">
          <a-pagination
              v-model:current="currentPage"
              :total="pagination.total"
              :page-size="20"
              :hideOnSinglePage="true"
              @change="onPageChange"
          />
        </div>
      </a-card>

    </a-col>
    <!-- / Projects Table Column -->

    <!--    Add New Category-->
    <a-modal
        v-model:modalOpen="modalOpen"
        title="Monthly Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeNewCategoryModal"
    >
      <div class="new-upload-data">
        <div>
          <label>Category Name</label>
          <a-input v-model="categoryName" type="text" placeholder="category name"/>
        </div>
        <div v-if="errorMsg.length" class="ant-btn-danger mt-5">{{ errorMsg }}</div>
      </div>
    </a-modal>

    <!-- Update Data-->
    <a-modal
        v-model:updateCategoryModal="updateCategoryModal"
        title="Feature Content Upload"
        :confirm-loading="confirmLoading"
        @ok="closeUpdateCategoryModal"
    >
      <div class="upload-data">
        <div>ID: {{ updateCategoryId }}</div>
        <div>
          <label>Category name:</label>
          <a-input type="text"
                   v-model="updateCategoryName"
                   placeholder="category name"/>
        </div>
        <div style="margin-top: 4px;">
          <label>Category Status: </label>
          <a-switch v-model:checked="updateCategoryStatus" style="margin-left: 6px;"/>
        </div>
        <div class="ant-btn-danger mt-5" v-if="errorMsg.length">{{ errorMsg }}</div>
      </div>
    </a-modal>

  </a-row>
</template>

<script>
import {sendRequest} from '@/http/axios.method';

const COLUMN_DATA = [
  {
    title: 'ID',
    dataIndex: 'id',
    scopedSlots: {customRender: 'id'},
    width: 90,
  },
  {
    title: 'Category Name',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    class: 'font-semibold text-muted',
  },

  {
    title: '',
    scopedSlots: {customRender: 'updateBtn'},
    width: 200,
  },
];
export default {
  name: "Category",
  data() {
    return {
      config: {
        'Authorization': 'Bearer ' + this.$store.state.authUser.token
      },
      columnData: COLUMN_DATA,
      categories: [],
      currentPage: 1,
      pagination: {},
      modalOpen: false,
      confirmLoading: false,
      categoryName: '',
      errorMsg: '',

      //Update Category
      updateCategoryModal: false,
      updateCategoryId: '',
      updateCategoryName: '',
      updateCategoryStatus: false,
    }
  },
  created() {
    this.getCategoryList();
  },
  methods: {
    getCategoryList() {
      this.confirmLoading = true;
      let data = {
        page: this.currentPage
      }
      sendRequest('get', 'admin/categories', data, this.config)
          .then(response => {
            this.confirmLoading = false;
            if (response.success) {
              let data = response.data.categories
              this.categories = data.map(function (content, label) {
                return {
                  key: label + 1,
                  id: content.id,
                  name: content.name,
                  status: content.status ? 'Active' : 'Inactive',
                  details: content
                };
              });
              this.pagination = response.data.meta.pagination
              this.currentPage = this.pagination.current_page
            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },
    onPageChange() {
      this.getCategoryList()
    },
    closeNewCategoryModal() {
      if (this.categoryName.length === 0) {
        this.errorMsg = 'PLease provide category name';
        return;
      }
      this.confirmLoading = true;
      this.errorMsg = '';
      let data = {
        name: this.categoryName
      }
      sendRequest('post', 'admin/categories', data, this.config)
          .then(response => {
            this.confirmLoading = false;
            if (response.success) {
              this.errorMsg = '';
              this.categoryName = '';
              this.modalOpen = false;
              this.getCategoryList();

            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
              this.errorMsg = response.message
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },
    showUpdateModal(selected_category) {
      this.updateCategoryId = selected_category.id;
      this.updateCategoryName = selected_category.name;
      this.updateCategoryStatus = !!selected_category.status;
      this.updateCategoryModal = true;
    },
    closeUpdateCategoryModal() {
      if (this.updateCategoryName.length === 0) {
        this.errorMsg = 'PLease provide category name';
        return;
      }
      this.confirmLoading = true;
      this.errorMsg = '';
      let data = {
        category_id: this.updateCategoryId,
        name: this.updateCategoryName,
        status: this.updateCategoryStatus
      }
      sendRequest('post', 'admin/update-category', data, this.config)
          .then(response => {
            this.confirmLoading = false;
            if (response.success) {
              this.errorMsg = '';
              this.updateCategoryId = '';
              this.updateCategoryName = '';
              this.updateCategoryStatus = false;
              this.updateCategoryModal = false;
              this.getCategoryList();

            } else {
              if (response.status_code === 401) {
                this.$store.dispatch('logout');
                this.$router.push('/login');
              }
              this.errorMsg = response.message
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          })
          .finally(() => {
            this.confirmLoading = false;
          })
    },
  },
  computed: {
    categoryList: function () {
      return this.categories;
    }
  }
}
</script>

<style scoped>

</style>
